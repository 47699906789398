@media (min-width: $large) {
    [menu-slide] {
        > [menu-slide-content] {
            background-color: transparent;
        }

        &[menu-slide-show-if-large="true"] {
            > [menu-slide-anchor] {
                display: none;
            }

            > [menu-slide-background] {
                transition: none;
                position: inherit;
                opacity: 1;
                pointer-events: auto;
            }

            > [menu-slide-content] {
                transition: none;
                max-width: 100%;
                position: inherit;
                overflow: visible;
                width: auto;
                left: 0;
            }
        }
    }
}