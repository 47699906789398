@media (min-width: $large) {
    #search-container {
        font-family: $font-family-header;
        background: linear-gradient(90deg, hsl(199, 72%, 25%), $primary-700);
        border-color: $primary-700;
        border-width: 1px 0 1px;
        border-style: solid;
        padding: $spacer-medium 0;
        min-height: calc(4rem + 5rem + 2px); // Padding x2 + Search button + borders x2
    }

    #filter-container {
        margin-bottom: $spacer-large;
        padding: $spacer-medium;
        background-color: $gray-000;
        min-height: calc(4rem + 5rem); // Padding x2 + Search button + borders x2
    }

    .search {
        padding: 0;

        .search-label {
            display: none;
        }

        .search-grid {
            display: grid; // Duplicate to avoid CLS
            grid-template-columns: 1fr auto;

            .search-line {
                display: grid; // Duplicate to avoid CLS
                grid-auto-flow: column;
                grid-auto-columns: minmax(0, 1fr);
                position: relative;

                .search-category {
                    .multiselect--active {
                        position: absolute;
                        left: 0;
                    }
                }
            }

            .search-submit button {
                height: 5rem;
                width: 5rem;
                border-radius: 50%;

                span {
                    display: none;
                }
            }
        }
    }
}