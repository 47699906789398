@media (min-width: $small) {
    a.event-result {
        border-radius: $border-radius-3;
        padding: $spacer-medium $spacer-large $spacer-large-plus;

        .event-result-flex {
            column-gap: $spacer-large;
        }
    }
}

@media (min-width: $medium) {
    .event-image-big {
        display: block;

        @include font-size(h5);
        text-align: center;
        height: 200px;
        width: 200px;

        .image-scale {
            box-shadow: $shadow-2;
            border-radius: $border-radius-2;
        }
    }

    .event-image-small--only {
        display: none;
    }

    .event-info-container {
        display: block;
    }
}
