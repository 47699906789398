@media (min-width: $tiny) {
    .card {
        .card-sub-title {
            margin-top: $spacer-large;
        }

        .card-text {
            margin: $spacer-large $spacer-medium auto;
        }
    }
}

@media (min-width: $medium) {
    .flex-card {
        grid-template-columns: 1fr 1fr;
    }

    .card {
        padding: $spacer-large;
    }
}
