.app-container {
    .app-container-child {
        .app-container-content {
            min-height: $height-app-content-top-only;

            #sticky-bottom {
                bottom: 0;
            }

            @media (min-width: $large) {
                #sticky-bottom {
                    position: sticky;
                }

                #sticky-bottom-placeholder {
                    display: none;
                }
            }
        }

        #menu-app {
            // TODO : Sticky bug remove
            &:after {
                display: none;
            }
        }
    }
}
