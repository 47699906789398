@media (min-width: $medium) {
    [menu-shrink] {
        [menu-shrink-content] {
            flex-direction: row;
        }

        [menu-shrink-trigger] {
            display: none;
        }

        [menu-shrink-item] {
            flex-grow: 1;
            display: block;
        }

        [menu-shrink-item="ul"] ul > ul {
            display: inherit;
        }

        [menu-shrink-item="separator"] {
            flex-grow: 0;
        }

        [menu-shrink-item="shrink"] {
            display: none;
        }
    }
}
