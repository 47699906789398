@media (min-width: $medium) {
    .route-list {
        [menu-shrink] [menu-shrink-content] {
            flex-wrap: wrap;
            column-gap: 10%;
            row-gap: $spacer-medium;
            justify-content: flex-start;

            > [menu-shrink-item] {
                flex: 0 1 45%;
            }
        }
    }
}

@media (min-width: $large) {
    .route-list {
        [menu-shrink] [menu-shrink-content] {
            column-gap: 5%;

            > [menu-shrink-item] {
                flex: 0 1 30%;
            }
        }
    }
}
