@media (min-width: $medium) {
    .banner {
        > .logo-txt-logo {
            flex-direction: row;

            > div:nth-child(2) {
                flex-grow: 1;
                margin-top: 0;
                text-align: right;
            }
        }
    }
}
