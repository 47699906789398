header {
    // TODO : Sticky bug remove
    &:after {
        display: none;
    }

    #menu-top {
        position: relative;
        top: auto;
        z-index: auto;

        height: $height-menu-top-medium;
        box-shadow: none;

        @include font-size(base);
        font-family: $font-family-header;
        font-weight: $weight-regular;

        #menu-top-wrapper {
            .menu-top-action {
                a, .a-like {
                    display: flex;
                    align-items: baseline;

                    > span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
