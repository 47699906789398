@media (min-width: $medium) {
    .cta-btn-right {
        grid-template-columns: 1fr 25%;
        text-align: inherit;
    }
}

@media (min-width: $small) and (max-width: $medium - 1) {
    .cta-btn-right {
        > .cta-btn-right--buttons {
            grid-auto-flow: column;
            justify-content: end;
        }
    }

    .cta-big-one {
        .cta-big-one--button {
            display: inline-block;
        }
    }
}