.app-container .app-container-child .app-container-content {
  min-height: calc(100vh - 45px);
}
.app-container .app-container-child .app-container-content #sticky-bottom {
  bottom: 0;
}
@media (min-width: 992px) {
  .app-container .app-container-child .app-container-content #sticky-bottom {
    position: sticky;
  }
  .app-container .app-container-child .app-container-content #sticky-bottom-placeholder {
    display: none;
  }
}
.app-container .app-container-child #menu-app:after {
  display: none;
}

@media (min-width: 768px) {
  .banner > .logo-txt-logo {
    flex-direction: row;
  }
  .banner > .logo-txt-logo > div:nth-child(2) {
    flex-grow: 1;
    margin-top: 0;
    text-align: right;
  }
}
.block h1 {
  margin-bottom: 4rem;
}
.block.block--h1-main {
  margin: 12rem 0 0 0;
}
.block.block--h1-main:nth-last-of-type(1) {
  margin-bottom: 12rem;
}
.block.block--color {
  margin: 16rem 0 0;
}
.block.block--color:nth-last-of-type(1) {
  margin-bottom: 16rem;
}
.block.block--color + .block {
  margin-top: 12rem;
}
.block.block--color > .sub-block--color {
  padding: 12rem 0;
}
.block.block--color.block--color--nearby {
  margin: 8rem 0 0;
}
.block.block--color.block--color--nearby > .sub-block--color {
  padding: 8rem 0;
}
.block.block--color.block--color--event-list > .sub-block--color {
  padding: 2rem 4rem;
}

@media (min-width: 576px) {
  #breadcrumb {
    line-height: 1.8em;
  }
}
@media (min-width: 768px) {
  .cta-btn-right {
    grid-template-columns: 1fr 25%;
    text-align: inherit;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cta-btn-right > .cta-btn-right--buttons {
    grid-auto-flow: column;
    justify-content: end;
  }
  .cta-big-one .cta-big-one--button {
    display: inline-block;
  }
}
@media (min-width: 400px) {
  .card .card-sub-title {
    margin-top: 4rem;
  }
  .card .card-text {
    margin: 4rem 2rem auto;
  }
}
@media (min-width: 768px) {
  .flex-card {
    grid-template-columns: 1fr 1fr;
  }
  .card {
    padding: 4rem;
  }
}
@media (min-width: 576px) {
  a.event-result {
    border-radius: 1.5rem;
    padding: 2rem 4rem 6rem;
  }
  a.event-result .event-result-flex {
    column-gap: 4rem;
  }
}
@media (min-width: 768px) {
  .event-image-big {
    display: block;
    font-size: 1.92rem;
    text-align: center;
    height: 200px;
    width: 200px;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .event-image-big {
    font-size: 2.34rem;
  }
}
@media (min-width: 768px) {
  .event-image-big .image-scale {
    box-shadow: 0 3px 6px hsla(4, 10%, 5%, 0.15), 0 2px 4px hsla(4, 10%, 5%, 0.12);
    border-radius: 0.7rem;
  }
  .event-image-small--only {
    display: none;
  }
  .event-info-container {
    display: block;
  }
}
.event-search--page-header {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  #footer-lazy {
    display: block;
  }
  #web-footer {
    margin: 0;
    font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 300;
  }
  #web-footer > .web-footer--color {
    background-color: hsl(4, 5%, 5%);
  }
  #web-footer::before {
    mask: url("../../../images/border-top.svg") repeat-x;
    content: "";
    display: block;
    height: 40px;
    mask-size: 1450px;
    background-color: hsl(4, 5%, 5%);
  }
  #web-footer #web-footer-content {
    font-size: 1.44rem;
    display: grid;
    padding: 8rem 0;
    grid-auto-flow: column;
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
  }
}
@media (min-width: 992px) and (min-width: 576px) {
  #web-footer #web-footer-content {
    font-size: 1.62rem;
  }
}
@media (min-width: 992px) {
  #web-footer .menu-v .menu-v-content .footer--title {
    font-size: 1.6rem;
    font-weight: 400;
    color: #fafafa;
    margin-top: 0px;
  }
}
@media (min-width: 992px) and (min-width: 576px) {
  #web-footer .menu-v .menu-v-content .footer--title {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  #web-footer .menu-v .menu-v-content .footer--title + div {
    margin-top: 2rem;
  }
  #web-footer .menu-v .menu-v-content > a, #web-footer .menu-v .menu-v-content div {
    border-radius: 0;
    min-height: inherit;
    line-height: inherit;
    padding: 0;
    margin-top: 0.7rem;
  }
  #web-footer .menu-v .menu-v-content > a + .footer--title, #web-footer .menu-v .menu-v-content div + .footer--title {
    margin-top: 2rem;
  }
  #web-footer .menu-v .menu-v-content:last-child {
    margin-bottom: 0;
  }
  #web-footer a {
    color: hsl(199, 64%, 59%);
  }
  #web-footer a:hover {
    color: hsl(199, 62%, 44%);
  }
}
header:after {
  display: none;
}
header #menu-top {
  position: relative;
  top: auto;
  z-index: auto;
  height: 45px;
  box-shadow: none;
  font-size: 1.6rem;
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
}
@media (min-width: 576px) {
  header #menu-top {
    font-size: 1.8rem;
  }
}
header #menu-top #menu-top-wrapper .menu-top-action a, header #menu-top #menu-top-wrapper .menu-top-action .a-like {
  display: flex;
  align-items: baseline;
}
header #menu-top #menu-top-wrapper .menu-top-action a > span, header #menu-top #menu-top-wrapper .menu-top-action .a-like > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Headings */
@media (min-width: 992px) {
  > .carousel-words {
    flex-direction: row;
    text-align: left;
  }
  .block.homepage-block--title {
    padding: 8rem 0 0;
  }
  .block.homepage-block--button {
    display: none;
  }
}
@media (min-width: 768px) {
  .center-left {
    text-align: left;
  }
}
@media (min-width: 576px) {
  .flex-align-middle {
    align-items: center;
    height: 100%;
  }
  .sticky:not(.sticky--bottom) {
    top: 0;
  }
  .sticky.sticky--bottom {
    bottom: 0;
  }
}
.map-desktop {
  display: block;
}

#map {
  border: 3px solid #fafafa;
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
  border-radius: 0.7rem;
  height: 600px;
}

@media (min-width: 768px) {
  [menu-shrink] [menu-shrink-content] {
    flex-direction: row;
  }
  [menu-shrink] [menu-shrink-trigger] {
    display: none;
  }
  [menu-shrink] [menu-shrink-item] {
    flex-grow: 1;
    display: block;
  }
  [menu-shrink] [menu-shrink-item=ul] ul > ul {
    display: inherit;
  }
  [menu-shrink] [menu-shrink-item=separator] {
    flex-grow: 0;
  }
  [menu-shrink] [menu-shrink-item=shrink] {
    display: none;
  }
}
@media (min-width: 992px) {
  [menu-slide] > [menu-slide-content] {
    background-color: transparent;
  }
  [menu-slide][menu-slide-show-if-large=true] > [menu-slide-anchor] {
    display: none;
  }
  [menu-slide][menu-slide-show-if-large=true] > [menu-slide-background] {
    transition: none;
    position: inherit;
    opacity: 1;
    pointer-events: auto;
  }
  [menu-slide][menu-slide-show-if-large=true] > [menu-slide-content] {
    transition: none;
    max-width: 100%;
    position: inherit;
    overflow: visible;
    width: auto;
    left: 0;
  }
}
@media (min-width: 576px) {
  .page-header {
    padding: 2rem;
    grid-template-columns: auto 1fr;
  }
  .page-header .page-header-image {
    height: 200px;
    width: 200px;
    margin-right: 2rem;
  }
  .page-header .page-header-block-1 {
    align-self: center;
  }
  .page-header .page-header-block-2 {
    grid-column: auto/span 2;
  }
}
@media (min-width: 992px) {
  .page-header {
    padding: 4rem;
  }
  .page-header .page-header-image {
    grid-row: auto/span 2;
  }
  .page-header .page-header-block-2 {
    grid-column: auto/span 1;
  }
}
@media (min-width: 576px) {
  .page-header .page-header-title {
    margin-top: 0;
  }
}
@media (min-width: 576px) {
  .page-header .page-header-short-desc {
    margin-top: 2rem;
  }
}

/* -------------------------- */
/* ==Responsive helpers       */
/* -------------------------- */
/* large screens */
/* ------------- */
@media (min-width: 992px) {
  /* layouts for large screens */
  .large-hidden {
    display: none !important;
  }
  .large-visible {
    display: block !important;
  }
  .large-no-float {
    float: none;
  }
  .large-inbl {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  /* widths for large screens */
  .large-w25 {
    width: 25% !important;
  }
  .large-w33 {
    width: 33.333333% !important;
  }
  .large-w50 {
    width: 50% !important;
  }
  .large-w66 {
    width: 66.666666% !important;
  }
  .large-w75 {
    width: 75% !important;
  }
  .large-w100,
  .large-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  /* margins for large screens */
  .large-man,
  .large-ma0 {
    margin: 0 !important;
  }
}
/* medium screens */
/* -------------- */
@media (min-width: 768px) and (max-width: 991px) {
  /* layouts for medium screens */
  .medium-hidden {
    display: none !important;
  }
  .medium-visible {
    display: block !important;
  }
  .medium-no-float {
    float: none;
  }
  .medium-inbl {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  /* widths for medium screens */
  .medium-w25 {
    width: 25% !important;
  }
  .medium-w33 {
    width: 33.333333% !important;
  }
  .medium-w50 {
    width: 50% !important;
  }
  .medium-w66 {
    width: 66.666666% !important;
  }
  .medium-w75 {
    width: 75% !important;
  }
  .medium-w100,
  .medium-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  /* margins for medium screens */
  .medium-man,
  .medium-ma0 {
    margin: 0 !important;
  }
}
/* small screens */
/* ------------- */
@media (min-width: 576px) and (max-width: 767px) {
  /* layouts for small screens */
  .small-hidden {
    display: none !important;
  }
  .small-visible {
    display: block !important;
  }
  .small-no-float {
    float: none;
  }
  .small-inbl {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  /* widths for small screens */
  .small-w25 {
    width: 25% !important;
  }
  .small-w33 {
    width: 33.333333% !important;
  }
  .small-w50 {
    width: 50% !important;
  }
  .small-w66 {
    width: 66.666666% !important;
  }
  .small-w75 {
    width: 75% !important;
  }
  .small-w100,
  .small-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0;
  }
  /* margins for small screens */
  .small-man,
  .small-ma0 {
    margin: 0 !important;
  }
  .small-pan,
  .small-pa0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .route-list [menu-shrink] [menu-shrink-content] {
    flex-wrap: wrap;
    column-gap: 10%;
    row-gap: 2rem;
    justify-content: flex-start;
  }
  .route-list [menu-shrink] [menu-shrink-content] > [menu-shrink-item] {
    flex: 0 1 45%;
  }
}
@media (min-width: 992px) {
  .route-list [menu-shrink] [menu-shrink-content] {
    column-gap: 5%;
  }
  .route-list [menu-shrink] [menu-shrink-content] > [menu-shrink-item] {
    flex: 0 1 30%;
  }
}
@media (min-width: 992px) {
  #search-container {
    font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    background: linear-gradient(90deg, hsl(199, 72%, 25%), hsl(199, 72%, 24%));
    border-color: hsl(199, 72%, 24%);
    border-width: 1px 0 1px;
    border-style: solid;
    padding: 2rem 0;
    min-height: calc(9rem + 2px);
  }
  #filter-container {
    margin-bottom: 4rem;
    padding: 2rem;
    background-color: hsl(4, 5%, 90%);
    min-height: 9rem;
  }
  .search {
    padding: 0;
  }
  .search .search-label {
    display: none;
  }
  .search .search-grid {
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .search .search-grid .search-line {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    position: relative;
  }
  .search .search-grid .search-line .search-category .multiselect--active {
    position: absolute;
    left: 0;
  }
  .search .search-grid .search-submit button {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
  }
  .search .search-grid .search-submit button span {
    display: none;
  }
}
@media (min-width: 596px) {
  .block .sub-block, .wrapper-large--margin, #sticky-bottom, .wrap-it-all {
    padding-left: 20px;
    padding-right: 20px;
  }
  .wrap-it-all {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (min-width: 1040px) {
  .block .sub-block, .wrapper-large--margin, #sticky-bottom {
    padding: 0;
  }
  .wrap-it-all {
    padding: 6rem 0 12rem 0;
  }
}

@media (min-width: 576px) {
  .fill {
    padding: 8rem;
  }
}
@media (min-width: 768px) {
  .fill {
    padding: 8rem 12rem;
  }
}
@media (min-width: 992px) {
  .fill {
    padding: 12rem;
  }
}