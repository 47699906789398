@media (min-width: $large) {
    #footer-lazy {
        display: block;
    }

    #web-footer {
        margin: 0;

        > .web-footer--color {
            background-color: $gray-900;
        }

        &::before {
            mask: url("../../../images/border-top.svg") repeat-x;
            content: "";
            display: block;
            height: 40px;
            mask-size: 1450px;
            background-color: $gray-900;
        }

        #web-footer-content {
            @include font-size(smaller);

            display: grid;
            padding: $spacer-extra-large 0;
            grid-auto-flow: column;
            grid-column-gap: $spacer-medium;
            grid-row-gap: $spacer-large;
        }

        font-family: $font-family-header;
        font-weight: $weight-book;

        .menu-v {
            .menu-v-content {
                .footer--title {
                    @include font-size(base);
                    font-weight: $weight-regular;
                    color: $white;
                    margin-top: 0px;

                    + div {
                        margin-top: $spacer-medium;
                    }
                }

                > a, div {
                    border-radius: 0;
                    min-height: inherit;
                    line-height: inherit;
                    padding: 0;
                    margin-top: $spacer-tiny-plus;

                    + .footer--title {
                        margin-top: $spacer-medium;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        a {
            color: $primary-300;

            &:hover {
                color: $primary-400;
            }
        }
    }
}
