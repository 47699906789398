.page-header {
    @media (min-width: $small) {
        padding: $spacer-medium;
        grid-template-columns: auto 1fr;

        .page-header-image {
            height: 200px;
            width: 200px;

            margin-right: $spacer-medium;
        }

        .page-header-block-1 {
            align-self: center;
        }

        .page-header-block-2 {
            grid-column: auto/span 2;
        }
    }

    @media (min-width: $large) {
        padding: $spacer-large;

        .page-header-image {
            grid-row: auto/span 2;
        }

        .page-header-block-2 {
            grid-column: auto/span 1;
        }
    }

    .page-header-title {
        @media (min-width: $small) {
            margin-top: 0;
        }
    }

    .page-header-short-desc {
        @media (min-width: $small) {
            margin-top: $spacer-medium;
        }
    }
}