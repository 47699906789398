/* Headings */

@media (min-width: $large) {
    > .carousel-words {
        flex-direction: row;
        text-align: left;
    }

    .block.homepage-block--title {
        padding: $spacer-extra-large 0 0;
    }

    .block.homepage-block--button {
        display: none;
    }
}
