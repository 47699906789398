.block {
    h1 {
        margin-bottom: $spacer-large;
    }

    &.block--h1-main {
        margin: $spacer-extra-large-plus 0 0 0;

        &:nth-last-of-type(1) {
            margin-bottom: $spacer-extra-large-plus;
        }
    }

    &.block--color {
        margin: $spacer-ultra-large 0 0;

        &:nth-last-of-type(1) {
            margin-bottom: $spacer-ultra-large;
        }

        + .block {
            margin-top: $spacer-extra-large-plus;
        }

        > .sub-block--color {
            padding: $spacer-extra-large-plus 0;
        }

        &.block--color--nearby {
            margin: $spacer-extra-large 0 0;

            >.sub-block--color {
                padding: $spacer-extra-large 0;
            }
        }

        &.block--color--event-list {
            >.sub-block--color {
                padding: $spacer-medium $spacer-large;
            }
        }
    }
}
