%wrapper-large {
    @media (min-width: $small + 20px) {
        &--margin, &--all-margin {
            padding-left: 20px;
            padding-right: 20px;
        }

        &--all-margin {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    @media (min-width: $wrapper-large + 40px) {
        &--margin {
            padding: 0;
        }
        &--all-margin {
            padding: $spacer-large-plus 0 $spacer-extra-large-plus 0;
        }
    }
}

.wrapper-large, #breadcrumb > ol, #sticky-bottom {
    @extend %wrapper-large !optional;
}

.block .sub-block, .wrapper-large--margin, #sticky-bottom {
    @extend %wrapper-large !optional;
    @extend %wrapper-large--margin !optional;
}

.wrap-it-all {
    @extend %wrapper-large !optional;
    @extend %wrapper-large--all-margin !optional;
}

.fill {
    @media (min-width: $small) {
        padding: $spacer-extra-large;
    }
    @media (min-width: $medium) {
        padding: $spacer-extra-large $spacer-extra-large-plus;
    }
    @media (min-width: $large) {
        padding: $spacer-extra-large-plus;
    }
}