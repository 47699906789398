@media (min-width: $medium) {
    .center-left {
        text-align: left;
    }
}

@media (min-width: $small) {
    .flex-align-middle {
        align-items: center;
        height: 100%;
    }

    .sticky {
        &:not(.sticky--bottom) {
            top: 0;
        }

        &.sticky--bottom {
            bottom: 0;
        }
    }
}