.map-desktop {
    display: block;
}

#map {
    border: $border-width-3 solid $white;
    box-shadow: $shadow-1;
    border-radius: $border-radius-2;
    height: 600px;
}
